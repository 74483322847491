<template>
  <div class="waiting-line">
    <header class="wl-header">
      <p class="logo" style="margin-top:0"><img alt="Logo" src="@/assets/logo.svg" /></p>
      <p class="name">{{ waiting_line.name }}</p>
    </header>
    <section class="wl-body">
      <div v-if="show_away_screen == true && appointments.length == 0" class="away-screen">
        <p class="away-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M127.1 0C141.3 0 151.1 10.75 151.1 24V64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V192H47.1V448C47.1 456.8 55.16 464 63.1 464H284.5C296.7 482.8 312.5 499.1 330.8 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0H127.1zM576 368C576 447.5 511.5 512 432 512C352.5 512 287.1 447.5 287.1 368C287.1 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368zM415.1 304V368C415.1 376.8 423.2 384 431.1 384H480C488.8 384 496 376.8 496 368C496 359.2 488.8 352 480 352H447.1V304C447.1 295.2 440.8 288 431.1 288C423.2 288 415.1 295.2 415.1 304V304z"/>
          </svg>
        </p>
        <h3>{{ waiting_line.away_screen_head }}</h3>
        <p>{{ waiting_line.away_screen_body }}</p>
        {{ waiting_line.opening_hours }}
      </div>
      <div v-else>
        <div v-if="appointments.length == 0" class="alert alert-info" style="margin:50px;">
          Aktuell sind keine Termine vorhanden.
        </div>
        <div v-else>
          <div class="row row-gutter-20">
            <div class="col-12">
              <h2 style="text-align:center">Nummer</h2>
            </div>
            <div class="col-12">
              <h2 v-if="waiting_line.name == 'Bürgerbüro'" style="text-align:center">Schalter</h2>
              <h2 v-else style="text-align:center">Zimmer</h2>
            </div>
          </div>
          <div v-for="appointment in appointments" v-bind:key="appointment.id">
            <div class="row row-gutter-20">
              <div class="col-12">
                <p style="text-align:center;font-size:80px;font-weight:600;margin:0 0 15px 0">
                  <span v-if="appointment.appointment_type == 'planned'">T{{ appointment.appointment_number }}</span>
                  <span v-if="appointment.appointment_type == 'spontaneous'">A{{ appointment.appointment_number }}</span>
                </p>
              </div>
              <div class="col-12">
                <p style="text-align:center;font-size:80px;font-weight:600;margin:0 0 15px 0">
                  <span v-if="appointment.counter && appointment.status == 'called'">{{ appointment.counter.name }}</span>
                  <span v-else>–</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="show_away_screen == true && appointments.length == 0" class="wl-pre-footer" style="background:#fff;padding: 30px 0;border-top:1px solid rgba(0, 0, 0, 0.1);">
      <p style="text-align:center;font-size:30px;font-weight:600;margin:0;color:#fff">-</p>
    </section>
    <section v-else class="wl-pre-footer" style="background:#fff;padding: 30px 0;border-top:1px solid rgba(0, 0, 0, 0.1);">
      <p style="text-align:center;font-size:30px;font-weight:600;margin:0">Halten Sie Ihr Ticket bereit und warten bitte auf Ihren Aufruf.</p>
    </section>
    <footer class="wl-footer">
      <p>{{ current_time }}</p>
    </footer>
  </div>
</template>

<script>
import aufio_file from '@/assets/announce.mp3';

export default {
  name: 'waiting_line',
  data () {
    return {
      waiting_line: {},
      current_time: null,
      old_appointments: [],
      appointments: [],
      called_appointments: [],
      show_away_screen: false
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/waiting_lines/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.waiting_line = response.data.waiting_line;
      });
      this.play_sound();
    },
    async get_appointments() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/appointments', {
        params: {
          waiting_line_id: this.$route.params.uuid
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.old_appointments = this.appointments;
        this.appointments = response.data.appointments;
        if (this.old_appointments.length == 0 && this.appointments.length >= 1) {
          this.play_sound();
        }
        this.appointments.forEach(app => {
          var app_ids = [];
          this.old_appointments.forEach(appointment => {
            app_ids.push(appointment.id);
          })
          if (!app_ids.includes(app.id)) {
            // Is not in array = new
            if (!this.called_appointments.includes(app.id)) {
              this.called_appointments.push(app.id);
              this.play_sound();
            }
          }
        });
      });
      if (this.waiting_line.show_away_screen == true) {
        this.check_opening_hours();
      }
    },
    time_add_zero(i) {
      if (i < 10) {i = "0" + i}
      return i;
    },
    get_now() {
      const today = new Date();
      const date = today.getDate()+'.'+(today.getMonth()+1)+'.'+today.getFullYear();
      const time = today.getHours() + ":" + this.time_add_zero(today.getMinutes());
      const dateTime = date +' '+ time;
      this.current_time = dateTime;
    },
    play_sound() {
      var audio = new Audio(aufio_file);
      audio.play();
    },
    async check_opening_hours() {
      // Away screen should be shown, we can check opening hours now.
      await this.$http.get(process.env.VUE_APP_BASE_API+'/waiting_lines/'+this.$route.params.uuid+'/opening_hours', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        if (response.data.open == false) {
          this.show_away_screen = true;
        } else {
          this.show_away_screen = false;
        }
      });
    }
  },
  mounted () {
    this.get_data();
    this.get_appointments();
    this.get_now();
    var _this = this;
    setInterval(function(){
      _this.get_appointments();
      _this.get_now();
    }, 1000);
  }
}
</script>

<style lang="scss">

  .away-screen {
    text-align: center;
    padding-top: 80px;

    .away-icon {

      svg {
        width: 140px;
        fill: #9c2128;
      }
    }

    h3 {
      font-size: 44px;
      margin: 50px 0 0 0;
    }

    p {
      font-size: 34px;
    }

  }
</style>
